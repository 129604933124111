@import '../../sass/helpers/variables';
@import '../../sass/helpers/mixins';

.root {
    color: $error-red;
}

.icon {
    @include sq(16px);
}
