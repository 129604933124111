@import '../../../../sass/helpers/variables';
@import '../../../../sass/helpers/mixins';

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: $bg_grey;
    color: $text_black;
}

.container {
    padding-top: 32px;
    padding-bottom: 32px;
    // The less supported bottom padding comes second in case a browser doesn't recognize it and will
    // use the first setting
    // https://css-tricks.com/the-notch-and-css/ for env(safe-area-inset-bottom)
    padding-bottom: calc(50px + env(safe-area-inset-bottom));
    text-align: left;

    > *:first-child {
        margin-top: 0;
    }

    @include mq-tablet-landscape {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include mq-tablet-portrait {
        text-align: center;
    }
}

.helpLinks {
    text-align: left;
    @include reset-list;

    > * + * {
        margin-top: 16px;
    }

    a {
        font-size: 16px;
        font-weight: 600;
        // NOTE Temporary RDS override since a11y link is from AEM and we can't make it a `<Link variant="external">` yet
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    @include mq-tablet-portrait {
        display: flex;
        align-items: center;
        justify-content: center;

        > * + * {
            margin-top: 0;
            margin-left: 8px;
            padding-left: 8px;
            border-left: 1px solid rgba(45, 45, 57, 0.3);
        }
    }
}

:global(.theme-optum) {
    .helpLinks {
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
