.phoneNumberContainer {
    // country code
    > * + * {
        margin-left: 16px;
        flex: 0 0 25%;
    }

    // phone number
    > :last-child {
        flex: 1 1 auto;
    }
}
