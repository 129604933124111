@import '../../../../../sass/helpers/mixins';
@import '../../../../../sass/optum/variables';

.dropdownButton {
    > span {
        pointer-events: none;
        transform: rotate(90deg);
        margin-top: 3px;
        margin-left: 5px;
        margin-right: 0;
    }

    &[aria-expanded='true'] > span {
        transform: rotate(270deg);
        margin-top: -5px;
        margin-left: 3px;
        margin-right: 2px;
    }
}

.dropdown {
    background-color: white;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 4px rgba(25, 25, 26, 0.16);
    display: none;
    max-height: 155px;
    overflow-y: auto;
    position: absolute;
    left: -35px;
    top: 25px;
    z-index: 2;
    cursor: pointer;

    &.openedDropdown {
        display: block;
    }
}

.dropdownElement {
    display: flex;
    align-items: center;
    padding: 2px 15px;
    font-size: 13px;
    font-weight: 500;
    margin: 5px;

    &[aria-current='true'] {
        color: $black;
        font-weight: 700;
    }
}

.checkMark {
    width: 13px;
    margin-left: 4px;

    polyline {
        stroke: $black;
        stroke-width: 2;
    }
}
