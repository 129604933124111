@import './mixins';

// Global CSS classes for component-esque UI. This can be a temporary staging ground for CSS classes that should
// probably be rolled into a React component.
.info-block {
    text-align: center;

    & > * + * {
        margin-top: 15px;
    }
}

// TODO Move into new component. Matt tried doing this, but supporting the html string link binding
// was taking too much time.
.legal-text {
    font-size: 13px;
    line-height: 16px;
    color: #757588;
}

.button-like-link {
    font-size: 1rem;
    font-weight: 600;
}

// TODO Replace with responsive spacing classes from RDS once they are available
.page-vertical-spacing {
    padding-top: 24px !important;
    padding-bottom: 24px !important;

    @include mq-desktop {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }
}

// NOTE Use this sparingly. It is mainly for the page headings so that we can set the focus starting point when a new
// page loads.
.hidden-focus:focus {
    outline: none;
    border: none;
}

// Global CSS classes for WebChat UI
.caip-web-chat {
    & > .chat-box {
        top: auto !important;
        height: calc(100% - 90px);
        z-index: 70;
    }

    & .header {
        height: auto !important;
        min-height: 58px;
    }

    & .title-text {
        height: auto !important;
        min-height: 24px;
    }

    & button {
        cursor: pointer;
    }
}
