@import 'src/sass/helpers/mixins';
@import 'src/sass/uhc/tokens/uhc.scss';

.cardContainer {
    + .cardContainer {
        margin-top: $rds-spacing-size-16;
    }
}

.multiColumn {
    @include mq-tablet-landscape {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
    }

    .cardContainer {
        @include mq-tablet-landscape {
            margin: 0 $rds-spacing-size-16;

            + .cardContainer {
                margin-top: 0;
            }
        }
    }
}

.cardImg {
    max-width: 313px;
    width: 100%;
    height: auto;

    @include center-block;
}
