@import '../helpers/mixins';
@import './variables';

.rds-primary-button,
.rds-primary-alt-button,
.rds-secondary-button,
.rds-tertiary-button {
    transition: transform 0.1s cubic-bezier(0.25, 0.1, 0.25, 1), border-color 0.1s cubic-bezier(0.25, 0.1, 0.25, 1),
        box-shadow 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);

    &:active {
        transform: scale(0.95);
    }
}

.rds-primary-button {
    background-color: $optum-blue;
    border-color: $optum-blue;
    color: $white;

    &:hover {
        background-color: $optum-blue-dark;
        border-color: $optum-blue-dark;
        box-shadow: 0 0 0 2px $optum-blue-dark;
        color: $white;
    }

    &:active {
        background-color: $optum-blue-dark;
        border-color: $optum-blue-dark;
        color: $white;
        box-shadow: 0 0 0 2px $white, 0 0 0 4px $optum-blue-dark;
        transform: scale(0.95);
    }

    @include focus-highlight {
        background-color: $optum-blue-dark;
        border-color: $optum-blue-dark;
        color: $white;
        box-shadow: 0 0 0 2px $white, 0 0 0 4px $optum-blue-dark;
    }
}

.rds-primary-alt-button {
    background-color: $white;
    border-color: transparent;
    color: $optum-blue;

    &:hover {
        background-color: rgba(34, 34, 34, 0.2);
        border-color: $white;
        box-shadow: 0 0 0 2px $white;
        color: $white;
    }

    &:active {
        background-color: rgba(34, 34, 34, 0.2);
        border-color: $white;
        box-shadow: 0 0 0 2px $white;
        color: $white;
    }

    @include focus-highlight {
        background-color: $optum-blue-dark;
        border-color: $optum-blue-dark;
        color: $white;
        box-shadow: 0 0 0 2px $white, 0 0 0 4px $optum-blue-dark;
    }
}

.rds-secondary-button {
    background-color: transparent;
    border-color: $optum-blue;
    color: $optum-blue;

    &:hover {
        background-color: transparent;
        border-color: $optum-blue-dark;
        box-shadow: 0 0 0 2px $optum-blue-dark;
        color: $optum-blue-dark;
    }

    &:active {
        background-color: transparent;
        border-color: $optum-blue-dark;
        box-shadow: 0 0 0 2px $white, 0 0 0 4px $optum-blue-dark;
        color: $optum-blue-dark;
    }

    @include focus-highlight {
        background-color: transparent;
        border-color: $optum-blue-dark;
        color: $optum-blue-dark;
        box-shadow: 0 0 0 2px $white, 0 0 0 4px $optum-blue-dark;
    }
}

// NOTE a tertiary button style is not defined by the DPL do we're leaving .rds-tertiary-button as-is since
// it only uses white and gary and no brand colors
