@import '../../../sass/helpers/mixins';

.root {
    max-width: 840px;
    @include center-block;
}

.centered {
    text-align: center;
}
