@import '../../../sass/helpers/mixins';

.root {
    display: flex;
    background-color: #fff;
    padding-top: 16px;
    padding-bottom: 8px;
    flex: 0 0 auto;

    @include mq-tablet-landscape {
        padding-top: 32px;
        padding-bottom: 16px;
    }
}

.container {
    display: flex;
    flex-direction: column;

    @include mq-tablet-landscape {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }
}

.title {
    text-align: center;
    margin-top: 16px;

    @include mq-tablet-landscape {
        margin-top: 0;
        flex-grow: 1;
    }
}

.closeButton {
    flex-shrink: 0;
    align-self: flex-end;

    @include mq-tablet-landscape {
        align-self: flex-start;
    }
}

// Generic Modal
// TODO Deprecate this style
.generic {
    .title {
        margin-left: 0;
    }

    h1,
    h2 {
        margin: 0;
    }

    h1 {
        text-align: left;
        padding: 40px 0;
        font-size: 45px;
    }
}

// Lightbox
.lightbox {
    .title {
        margin-left: 0;
    }

    h1 {
        text-align: left;
        margin: 0;
        font-size: 30pt;
        font-weight: 300;
        padding: 20px 0;
    }
}
