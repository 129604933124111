@import 'src/sass/uhc/tokens/uhc.scss';
@import 'src/sass/helpers/mixins';

.SelectTrigger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    font-size: $rds-font-size-18;
    border: 1px solid $rds-color-neutral-50;
    margin: -1px;
}

.SelectTrigger:focus {
    border: 2px solid $rds-focus-outline-color;
    outline: none;
}

.SelectContent {
    z-index: 1000;
    overflow: auto;
    background-color: $white;
    border-radius: $rds-border-radius-4;
    box-shadow: $rds-box-shadow-neutral-with-spread-10;
    max-height: 300px;
}

.SelectValue {
    display: flex;
    align-items: center;
}

.SelectViewport {
    padding: 5px;
}

.SelectItem {
    font-size: $rds-font-size-13;
    line-height: 1;
    color: $rds-color-system-uhc-grey;
    border-radius: $rds-border-radius-4;
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 35px 0 25px;
    position: relative;
    user-select: none;
}

.SelectItem[data-disabled] {
    color: $rds-color-system-uhc-grey;
    pointer-events: none;
}

.SelectItem[data-highlighted] {
    outline: none;
    background-color: $rds-color-system-uhc-blue;
    color: $white;
}

.CountryFlag {
    background-image: url('../../../assets/images/country-flags.png');
    border-radius: 3px;
    background-size: cover;
    width: 18px;
    height: 12px;
    margin-right: 6px;
    background-repeat: no-repeat;
    display: inline-block;
}

.icon {
    margin-left: $rds-spacing-size-8;
}
