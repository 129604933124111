@mixin font-family-base($font-family: null) {
  @if $font-family {
    font-family: $font-family;
  } @else {
    font-family: $family-sans-serif;
  }
}

@mixin apply-typography($size) {
  $typography: map-get($typography-sizes, $size);
  $font-family: map-get($typography, 'font-family');
  @include font-family-base($font-family);
  font-size: map-get($typography, 'size');
  font-weight: map-get($typography, 'weight');
  line-height: map-get($typography, 'line-height');
}

@mixin apply-typography-touch($size) {
  $typography: map-get($typography-sizes, $size);
  @include touch {
    font-size: map-get($typography, 'touch-size');
    font-weight: map-get($typography, 'touch-weight');
    line-height: map-get($typography, 'touch-line-height');
  }
}
// What to do with these?
@mixin typography-size($target: '') {
  @each $size in $sizes {
    .is-#{$prefix}-#{map-get($size, name)}#{if($target == '', '', '-' + $target)} {
      font-size: #{map-get($size, value)} !important;
    }
  }
}

@mixin typography-line-height($target: '') {
  @each $key, $line-height in $line-heights {
    .is-#{$prefix}-line-height-#{$key}#{if($target == '', '', '-' + $target)} {
      line-height: #{$line-height} !important;
    }
  }
}
