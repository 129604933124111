@import 'src/sass/uhc/tokens/uhc.scss';
@import 'src/sass/helpers/variables';
@import 'src/sass/helpers/mixins';

.item {
    margin-top: 4px;
    align-items: center;
}

.icon {
    @include sq(12px);
    margin-right: 4px;
}

.default {
    color: $rds-color-neutral-50;
}

.invalid {
    color: $error-red;
}

.valid {
    color: $green;
}
