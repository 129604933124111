/**
 * Headings
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $family-sans-serif;
  margin-top: 0;
}
