@import '../../../sass/helpers/mixins';

.root {
    color: #333;
}

.legendsContainer {
    @include mq-tablet-landscape {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.legendContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    + .legendContainer {
        margin-top: 8px;

        @include mq-tablet-landscape {
            margin-top: 0;
            margin-left: 16px;
        }
    }
}

.legendImg {
    width: 27px;
    height: 19px;
    overflow: hidden;
}

.accordionOuter {
    background: #f3f3f3;
    padding-bottom: 64px;
    // Negate the bottom padding on the modal body
    margin-bottom: -24px;
}
