@import '../../sass/helpers/mixins';

// Modal will only provide grey background for all sizes of Modals
.backdrop {
    position: fixed;
    @include center-flex-vh;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 60;
}

.root {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    flex: 1 1 auto;

    &:focus {
        outline: none;
    }
}

// Modal defaults to fullscreen style. Lightbox style applies CSS overrides.
.lightbox {
    max-width: 624px;
    width: 100%;

    @include mq-desktop {
        margin-left: 20px;
        margin-right: 20px;
        // Don't set the max-height until the screen is big enough to show lightbox version
        max-height: 540px;
        height: auto;
    }
}

// HACK This `display` override fixes a horizontal centering bug in IE11 for the lightbox modal
// using an IE11-specific CSS targeting hack
// SEE https://stackoverflow.com/a/22085269
*::-ms-backdrop,
.lightbox {
    display: block;
}
