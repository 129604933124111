@mixin c-apply-select() {
  $select-focus-state: map-get($select-properties, 'focus');
  $select-hover-state: map-get($select-properties, 'hover');
  $select-right-padding: $control-padding-horizontal + $rds-spacing-size-16;

  @include apply-control-base;
  @include unselectable;

  background-color: map-get($select-properties, 'background-color');
  background-image: get-dropdown-triangle-svg(16, encode-color(map-get($select-properties, 'color')));
  background-position: calc(100% - #{$rds-spacing-size-16}) 45%;
  background-repeat: no-repeat;
  border-color: map-get($select-properties, 'border-color');
  color: map-get($select-properties, 'color');
  cursor: pointer;
  padding: 0 $select-right-padding 0 $rds-spacing-size-16;

  &:focus {
    box-shadow: map-get($select-focus-state, 'box-shadow');
  }

  // takes effect when focusing through keyboard tab navigation
  // https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring
  &:-moz-focusring {
    text-shadow: 0 0 0 #000;
  }

  &:hover {
    background-color: map-get($select-hover-state, 'background-color');
  }

  &:disabled {
    background-color: map-get($select-properties, 'background-color');
    opacity: $control-disabled-opacity;
  }

  // The ::-ms-expand CSS pseudo-element is a Microsoft extension that represents the button of a menu control that opens or closes the drop-down menu.
  &::-ms-expand {
    display: none;
  }

  // The ::-ms-value pseudo-element applies rules to the value/content of an <input> or a <select>.
  &::-ms-value {
    background: none;
    color: map-get($select-properties, 'color');
  }

  option {
    font-weight: $weight-normal;
    line-height: 1.5;
  }

  // TODO: Determine if design wants these variants
  // &.is-#{$prefix}-fullwidth {
  //   @include apply-control-fullwidth;
  // }

  // &.is-#{$prefix}-small {
  //   @include apply-control-small;
  //   background-image: get-dropdown-triangle-svg(12, encode-color(map-get($select-properties, 'color')));
  //   background-position: calc(100% - #{$rds-spacing-size-16}) 40%;
  // }
}
