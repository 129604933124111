@import 'src/sass/uhc/tokens/uhc.scss';
@import 'src/sass/helpers/variables';

.root {
    padding: 15px 10px;
    background-color: #fbf1e2;
    color: #ac2b00;
    max-width: 468px;
    text-align: left;

    &:focus {
        outline: 2px solid $focus-ring-color;
    }

    a {
        color: inherit !important;
        text-decoration: underline;
    }
}

.iconWrapper {
    align-items: flex-start;
}

.fieldErrorList {
    list-style: disc;

    > * {
        margin-top: 4px;
    }
}

.fieldErrorLink {
    font-weight: normal;
}
