/**
 * Inline Elements
 */

em,
i {
  font-family: $rds-font-family;
  font-style: italic;
  font-weight: 400;
}

small {
  @include apply-typography('sm');
  @include apply-typography-touch('sm');
}

strong,
b {
  font-family: $rds-font-family;
  font-style: normal;
  font-weight: 700;
}
