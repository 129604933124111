@import '../../sass/optum/variables';

.root {
    position: absolute;
    top: 0;
    width: 100%;
    height: 10px;
    color: #1898da;
}

:global(.theme-optum) .root {
    color: $optum-blue;
}

.bar {
    width: 0;
    height: 100%;
    background-color: currentColor;
    user-select: none;
    // NOTE can't use `animation` property shorthand because CSS Module does weird things with the animation name
    // https://github.com/css-modules/css-modules/issues/141#issuecomment-293092109
    animation-name: loadingBar;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes loadingBar {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
