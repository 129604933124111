@each $typography-size, $params in $typography-sizes {
  .is-#{$prefix}-#{$typography-size} {
    @include apply-typography($typography-size);
    @include apply-typography-touch($typography-size);
  }
}

@each $typography-size, $params in $typography-sizes {
  .is-#{$prefix}-#{$typography-size}-touch {
    @include apply-typography-touch($typography-size);
  }
}
