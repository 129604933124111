// u- prefix to identify this is utilitiy mixin - modifies a single property.
@mixin u-link-text-decoration($has-text-decoration: false) {
  @if $has-text-decoration == true {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &:active {
      text-decoration: underline;
    }
  }
}
// This applies to the top positioning to the carets for standalone links
// Since each font renders differently, each theme needs to tweak the caret's vertical position
@mixin h-rds-link-sa-top($font-size) {
  top: map-deep-get($links, 'sa', 'h-top', $font-size);
}

// This applies to the top positioning to the link out icons for external links
// Since each font renders differently, each theme needs to tweak the link out's vertical position
@mixin h-rds-link-ext-top($font-size) {
  $margin-top-map: map-deep-get($links, 'external', 'h-mt');

  @if ($margin-top-map) {
    margin-top: map-get($margin-top-map, $font-size);
  }
}

// Adds the neutral link color
@mixin u-neutral-link-color() {
  color: $link-neutral-color;

  &:hover {
    color: $link-neutral-color-hover;
  }

  &:active {
    color: $link-neutral-color-active;
  }
}

// Helper for applying the External Link variations
@mixin u-ext-link-variants($is-footer: false) {
  @if $is-footer {
    $external-link-footer: map-deep-get($links, 'external', 'footer');

    font-weight: map-get($map: $external-link-footer, $key: 'font-weight');
  } @else {
    $external-link-inline: map-deep-get($links, 'external', 'inline');

    font-weight: map-get($map: $external-link-inline, $key: 'font-weight');
  }
}
// Helper for the External Link base CSS
@mixin u-ext-link-base() {
  @extend %center-element;

  .#{$prefix}-icon {
    @include u-set-spacing-style('link-icon-right');
  }

  // Position hacks to line link out up to the rendered font
  &.is-#{$prefix}-body-2 .#{$prefix}-icon {
    @include h-rds-link-ext-top('body-2');

    @include touch {
      @include h-rds-link-ext-top('touch-body-2');
    }
  }

  &.is-#{$prefix}-body-3 .#{$prefix}-icon {
    @include h-rds-link-ext-top('body-3');

    @include touch {
      @include h-rds-link-ext-top('touch-body-3');
    }
  }
}

// Applies the different link variants based on the variant name
@mixin c-apply-link-variant($variant-name: 'inline') {
  @extend %rds-link;

  @if $variant-name == 'back' {
    $back-link-icon-margin-right: rem(4px);
    $back-link-h-top-lh: map-deep-get($links, 'back', 'h-text-lh');

    @extend %center-element;
    font-size: $size-14;
    font-weight: $weight-bold;
    line-height: $line-height-16;
    text-decoration: none;

    .#{$prefix}-icon {
      margin-right: $back-link-icon-margin-right;
    }

    @if $back-link-h-top-lh {
      span:last-child {
        line-height: rem($back-link-h-top-lh);
      }
    }
  } @else if $variant-name == 'ext-footer' {
    @include u-neutral-link-color();
    @include u-ext-link-variants(true);
    @include u-ext-link-base();
  } @else if $variant-name == 'ext-inline' {
    @include u-ext-link-variants();
    @include u-ext-link-base();
  } @else if $variant-name == 'footer' {
    @include u-neutral-link-color();
  } @else if $variant-name == 'icon-left' {
    @extend %center-element;
    font-weight: map-deep-get($links, 'icon-left', 'font-weight');

    .#{$prefix}-icon {
      @include u-set-spacing-style('link-icon-left');
    }
  } @else if $variant-name == 'inline' {
    @include u-link-text-decoration(true);
  } @else if $variant-name == 'sa' {
    @extend %center-element;

    font-weight: map-deep-get($links, 'sa', 'font-weight');
    padding-right: 1rem;
    position: relative;

    &.is-#{$prefix}-body-2,
    &.is-#{$prefix}-body-3,
    &.is-#{$prefix}-sm {
      .#{$prefix}-icon {
        position: absolute;
        right: 0;
      }
    }

    // Position hacks to line caret up to the rendered font
    &.is-#{$prefix}-body-2 .#{$prefix}-icon {
      @include h-rds-link-sa-top('body-2');

      @include touch {
        @include h-rds-link-sa-top('touch-body-2');
      }
    }

    &.is-#{$prefix}-body-3 .#{$prefix}-icon {
      @include h-rds-link-sa-top('body-3');

      @include touch {
        @include h-rds-link-sa-top('touch-body-3');
      }
    }

    &.is-#{$prefix}-sm .#{$prefix}-icon {
      @include h-rds-link-sa-top('sm');

      @include touch {
        @include h-rds-link-sa-top('touch-sm');
      }
    }
  }
}

// c- prefix to identify this as a component mixin.
@mixin c-rds-link($base_class: &) {
  // List of all of the current variants
  // ext-footer and ext-inline are left out to combine the selectors to keep on par with what is already implemented
  // footer left out to combine selectors for applying the neutral color
  $link-variants: 'back', 'heading', 'icon-left', 'inline', 'sa';

  @each $link-variant in $link-variants {
    #{$base_class}-#{$link-variant} {
      @include c-apply-link-variant($link-variant);
    }
  }

  // Avoid CSS duplication here
  #{$base_class}-ext-footer,
  #{$base_class}-ext-inline {
    @extend %rds-link;
    @include u-ext-link-base();
  }

  #{$base_class}-ext-footer,
  #{$base_class}-footer {
    @include u-neutral-link-color();
  }

  #{$base_class}-ext-footer {
    @include u-ext-link-variants(true);
  }

  #{$base_class}-ext-inline {
    @include u-ext-link-variants();
  }

  .#{$is-prefix}-neutral-link {
    @include u-neutral-link-color();
  }
}
