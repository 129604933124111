@import 'src/sass/uhc/tokens/uhc.scss';
@import 'src/sass/helpers/mixins';

@mixin horizontal-layout {
    flex-direction: row;

    // Remove all left/right margin of children to allow ButtonGroup to manage the spacing
    * {
        margin-left: 0;
        margin-right: 0;
    }

    > * + * {
        // NOTE Zero out the top margin for responsive layout
        margin-top: 0;
        margin-left: $rds-spacing-size-16;
    }
}

@mixin vertical-layout {
    flex-direction: column;

    // Remove all top/bottom margin of children to allow ButtonGroup to manage the spacing
    * {
        margin-top: 0;
        margin-bottom: 0;
    }

    > * + * {
        // NOTE Zero out the left margin for responsive layout
        margin-left: 0;
        margin-top: $rds-spacing-size-24;
    }
}

.root {
    display: flex;

    > * {
        flex: 1 1 auto;
    }

    &.layoutResponsive {
        @include vertical-layout;

        @include mq-tablet-portrait {
            @include horizontal-layout;
        }
    }

    &.layoutHorizontal {
        @include horizontal-layout;
    }

    &.layoutVertical {
        @include vertical-layout;
    }
}
