@mixin c-apply-checkbox() {
  @include apply-typography('body-3');

  align-items: center;
  color: map-get($checkbox-properties, 'active-color');
  cursor: pointer;
  display: inline-flex;
  font-weight: map-get($checkbox-properties, 'weight');
  justify-content: center;
  position: relative;

  .#{$prefix}-checkbox-text {
    color: map-get($checkbox-properties, 'base-color');
  }

  .#{$prefix}-checkmark {
    background-color: map-get($checkbox-properties, 'background-color');
    border: 0.125rem solid map-get($checkbox-properties, 'base-color-input');
    border-radius: rem(2px);
    height: 1.5rem;
    left: 0;
    margin-right: map-get($spacing-sizes, 'size-8');
    position: relative;
    top: 0;
    width: 1.5rem;

    &::after {
      border: solid map-get($checkbox-properties, 'checkmark-color');
      border-width: 0 rem(2px) rem(2px) 0;
      content: '';
      display: none;
      height: rem(12px);
      left: rem(8px);
      position: absolute;
      top: rem(2px);
      transform: rotate(45deg);
      width: rem(6px);
    }
  }

  &:hover {
    // just change checkbox border-color on hover
    .#{$prefix}-checkmark {
      border-color: map-get($checkbox-properties, 'hover-color');
    }
  }

  input[type='checkbox'] {
    @include u-sr-only();
    opacity: 0; // Added to ensure checkbox is not shown in IE11

    &:checked {
      ~ .#{$prefix}-checkmark {
        background-color: currentColor;
        border-color: currentColor;
        &::after {
          display: inline-flex;
        }
      }
    }

    &:focus ~ .#{$prefix}-checkmark {
      box-shadow: $focus-box-shadow-with-inset;
    }
  }

  &.#{$is-prefix}-disabled {
    &:hover {
      color: map-get($checkbox-properties, 'base-color');
    }

    input[type='checkbox']:checked ~ .#{$prefix}-checkmark {
      background-color: map-get($checkbox-properties, 'base-color');
      border-color: map-get($checkbox-properties, 'base-color');
    }
  }
}
