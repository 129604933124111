/**
 * Media
 */

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

figure {
  margin: 0;
}

// Constrain images and videos to the parent width

img,
video {
  height: auto;
  max-width: 100%;
}

// If a `width` and/or `height` attribute have been explicitly defined, let’s not make the image fluid.

img[width],
img[height] {
  max-width: none;
}
