// base style
%rds-link {
  background-color: transparent; // Remove the gray background on active links in IE 10.
  color: $link;
  text-decoration: none;
  text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

  &:hover {
    color: $link-hover;
    text-decoration: underline;
  }

  &:active {
    color: $link-active;
  }

  &:focus {
    outline: $focus-outline;
  }
}
