@import '../uhc/uhc-settings-tools.scss';
@import '../uhc/tokens/uhc.scss';
@import 'mixins';

.longform {
    table {
        // table responsive CSS
        // implementation reference: https://en.m.wikipedia.org/wiki/List_of_Robin_Williams_performances
        display: block;
        max-width: 100%;
        overflow-x: auto;
        border: 0; // adding display: block; adds a border around the table. To remote it, border: 0; needed
        border-color: grey; // adding display: block; removes the border color of the table, re-applying it here
    }

    // Set standard bottom margin of top-level content elements
    h2,
    h3,
    h4,
    h5 {
        margin-top: $rds-spacing-size-24;

        &:not(:last-child) {
            margin-bottom: $rds-spacing-size-8;
        }
    }

    ul,
    ol,
    dl,
    p,
    table {
        &:not(:last-child) {
            margin-bottom: $rds-spacing-size-16;
        }
    }

    h2 {
        @include apply-typography('h3');
    }

    h3 {
        @include apply-typography('h4');
    }

    h4 {
        @include apply-typography('h5');
    }

    h5 {
        @include apply-typography('h6');
    }

    ul,
    ol {
        padding-left: $rds-spacing-size-24;

        // Remove bottom margin on nested list wrapper elements
        ul,
        ol {
            margin-bottom: 0;
        }
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    li {
        @include remove-children-top-bottom-margin;

        + li {
            margin-top: $rds-spacing-size-8;
        }
    }

    a[target='_blank']::after {
        margin-left: #{$rds-spacing-size-8/2};
    }

    th,
    td {
        @include remove-children-top-bottom-margin;
        padding: $rds-spacing-size-8 $rds-spacing-size-16;
    }
}
