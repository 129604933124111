@import './variables';

input:not([type='button']):not([type='submit']):not([type='reset']),
select {
    &:focus {
        outline: 2px solid $optum-blue-dark !important;
    }
}

select {
    &:focus {
        outline-offset: 0 !important;
        border-color: transparent !important;
    }
}
