@mixin utility-responsive($responsive-size) {
  @if $responsive-size == 'touch' {
    @include touch {
      @content;
    }
  }
  @if $responsive-size == 'desktop' {
    @include desktop {
      @content;
    }
  }
  @if $responsive-size == 'widescreen' {
    @include widescreen {
      @content;
    }
  }
  @if $responsive-size == 'tablet' {
    @include tablet {
      @content;
    }
  }
  @if $responsive-size == 'tablet-portrait' {
    @include tablet-portrait {
      @content;
    }
  }
  @if $responsive-size == 'mobile' {
    @include mobile {
      @content;
    }
  }
}

@mixin utility-css($util-classname, $properties, $value, $responsive-size: null) {
  $postfix: if($responsive-size, '-' + $responsive-size, '');

  .#{$util-classname}#{$postfix} {
    @each $property in $properties {
      #{$property}: $value if($enable-important-utilities, !important, null);
    }
  }
}

@mixin generate-utility($utility) {
  $values: map-get($utility, 'values');
  $is-responsive: map-get($utility, 'responsive');
  $properties: map-get($utility, 'properties');

  $property-class: map-get($utility, 'class');

  // If the values are a list or string, convert it into a map
  @if type-of($values) == 'string' or type-of(nth($values, 1)) != 'list' {
    $values: zip($values, $values);
  }

  @each $key, $value in $values {
    $property-class-modifier: if($key, if($property-class, '-', '') + $key, '');

    $util-prefix: if(map-has-key($utility, 'prefix'), map-get($utility, 'prefix') + '-', 'is-');
    $util-classname: #{$util-prefix}#{$property-class}#{$property-class-modifier};

    @if $value != null {
      @include utility-css($util-classname, $properties, $value);

      @if $is-responsive {
        @each $responsive-size in $responsive-sizes {
          @include utility-responsive($responsive-size) {
            @include utility-css($util-classname, $properties, $value, $responsive-size);
          }
        }
      }
    }
  }
}
