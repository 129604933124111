@at-root {
  @font-face {
    font-family: 'UHC Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('~src/assets/fonts/UHC/UHC2020Sans-Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'UHC Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('~src/assets/fonts/UHC/UHC2020Sans-Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'UHC Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('~src/assets/fonts/UHC/UHC2020Sans-SemiBold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'UHC Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('~src/assets/fonts/UHC/UHC2020Sans-Bold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'UHC Serif';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('~src/assets/fonts/UHC/UHCSerifHeadline-Semibold.woff2') format('woff2');
  }
}
