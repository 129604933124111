@import '../../../sass/helpers/mixins';
@import '../../../sass/optum/variables';

.root {
    overflow-y: auto;
    flex: 1 1 auto;
    padding-bottom: 24px;

    @include remove-children-top-bottom-margin;

    @include focus-highlight {
        outline: 3px solid #0096dc;
    }
}

:global(.theme-optum) {
    .root {
        @include focus-highlight {
            outline: 2px solid $optum-blue-dark;
        }
    }
}

.isLightbox {
    padding-left: 24px;
    padding-right: 24px;
}
