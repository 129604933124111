// UHC tokens

$rds-box-shadow-with-inset: 0 0 0 1px #ffffff, 0 0 0 4px #0096dc; // Use when box shadow is used to create the outline focus on dark solid elements
$rds-box-shadow-solid-light: 0 0 0 3px #e5e5e5; // Use when box shadow is used to create the outline focus on elements with white background
$rds-box-shadow-solid-dark: 0 0 0 3px #0096dc; // Use when box shadow is used to create the outline focus on elements with white background
$rds-box-shadow-radius-0: 0px;
$rds-box-shadow-radius-4: 4px;
$rds-box-shadow-radius-8: 8px;
$rds-box-shadow-radius-12: 12px;
$rds-box-shadow-radius-20: 20px;
$rds-box-shadow-radius-circle: 50%;
$rds-box-shadow-radius-rounded: 290486px;
$rds-box-shadow-neutral-with-spread-6: 0px 2px 6px rgba(0, 0, 0, 0.2); // box shadow that is not solid and its size is determined by the spread, 20 at end of hex is the alpha value
$rds-box-shadow-neutral-with-spread-10: 0px 6px 10px rgba(0, 0, 0, 0.2); // box shadow that is not solid and its size is determined by the spread, 20 at end of hex is the alpha value
$rds-color-system-uhc-brand-blue: #002677; // UHC Brand Blue
$rds-color-system-uhc-blue: #196ecf; // Primary Color - Blue
$rds-color-system-uhc-blue-dark: #115fa0;
$rds-color-system-uhc-grey: #5a5a5a; // Body Text Color
$rds-color-system-uhc-grey-dark: #333333;
$rds-color-system-kelly-green-light: #f2f8e6;
$rds-color-system-kelly-green: #1ea21c; // Success
$rds-color-system-kelly-green-dark: #007000;
$rds-color-system-amber-orange-light: #fffbeb;
$rds-color-system-amber-orange: #e06800; // Warning - Orange
$rds-color-system-amber-orange-dark: #c24e14;
$rds-color-system-ruby-red-light: #fff5f7;
$rds-color-system-ruby-red: #f04c57; // Danger - Red
$rds-color-system-ruby-red-dark: #aa2e23;
$rds-color-system-olympic-blue-light: #e5f8fb;
$rds-color-system-olympic-blue: #0096dc;
$rds-color-system-olympic-blue-dark: #0248ac; // Info
$rds-color-brand: #002677;
$rds-color-primary: #196ecf;
$rds-color-primary-dark: #115fa0;
$rds-color-on-primary: #ffffff;
$rds-color-outline: #0096dc;
$rds-color-white: #ffffff;
$rds-color-neutral-98: #fafafa;
$rds-color-neutral-95: #f3f3f3;
$rds-color-neutral-90: #e5e5e5;
$rds-color-neutral-80: #cccccc;
$rds-color-neutral-50: #808080;
$rds-color-neutral-30: #4d4d4d;
$rds-color-neutral-20: #333333;
$rds-color-black: #000000;
$rds-active-outline-color: #0096dc;
$rds-active-outline-color-on-color: #ffffff;
$rds-active-box-shadow: 0 0 0 3px #e5e5e5;
$rds-focus-outline-color: #0096dc;
$rds-focus-outline-width: 3px;
$rds-focus-box-shadow: 0 0 0 1px #ffffff, 0 0 0 4px #0096dc;
$rds-focus-box-shadow-for-solid-color: 0 0 0 3px #0096dc;
$rds-space-one-x: 8px;
$rds-space-two-x: 16px;
$rds-space-three-x: 24px;
$rds-space-four-x: 32px;
$rds-space-five-x: 40px;
$rds-space-six-x: 48px;
$rds-space-eight-x: 64px;
$rds-typography-h1: 2.281rem;
$rds-typography-h2: 2.027rem;
$rds-typography-h3: 1.802rem;
$rds-typography-h4: 1.602rem;
$rds-typography-h5: 1.424rem;
$rds-typography-h6: 1.266rem;
$rds-typography-body-1: 1.125rem;
$rds-typography-body-2: 1rem;
$rds-typography-body-3: 0.889rem;
$rds-typography-small: 0.79rem;
$rds-typography-micro: 0.702rem;
$rds-typography-mobile-h1: 1.802rem;
$rds-typography-mobile-h2: 1.602rem;
$rds-typography-mobile-h3: 1.424rem;
$rds-typography-mobile-h4: 1.266rem;
$rds-typography-mobile-h5: 1.125rem;
$rds-typography-mobile-h6: 1rem;
$rds-typography-mobile-body: 0.889rem;
$rds-typography-mobile-small: 0.79rem;
$rds-typography-mobile-micro: 0.702rem;
$rds-font-family: 'UHC Sans', Helvetica, Arial, sans-serif;
$rds-font-family-serif: 'UHC Serif', Georgia, serif;
$rds-font-size-11: 0.702rem; // Micro / 11px (rounded) and Mobile Micro / 11px (rounded)
$rds-font-size-13: 0.79rem; // Small / 13px (rounded) and Mobile Small / 13px (rounded)
$rds-font-size-14: 0.889rem; // Body 3 / 14px (rounded) and All Mobile Body / 14px (rounded)
$rds-font-size-16: 1rem; // Body 2 / 16px (rounded) and Mobile Heading 6 / 16px (rounded)
$rds-font-size-18: 1.125rem; // Body 1 / 18px (rounded) and Mobile Heading 5 / 18px (rounded)
$rds-font-size-20: 1.266rem; // Heading 6 / 20px (rounded) and Mobile Heading 4 / 20px (rounded)
$rds-font-size-23: 1.424rem; // Heading 5 / 23px (rounded) and Mobile Heading 3 / 23px (rounded)
$rds-font-size-26: 1.602rem; // Heading 4 / 26px (rounded) and Mobile Heading 2 / 26px (rounded)
$rds-font-size-29: 1.802rem; // Heading 3 / 29px (rounded) and Mobile Heading 1 / 29px (rounded)
$rds-font-size-32: 2.027rem; // Heading 2 / 32px (rounded)
$rds-font-size-36: 2.281rem; // Heading 1 / 36px (rounded)
$rds-font-weight-light: 300;
$rds-font-weight-regular: 400;
$rds-font-weight-medium: 500;
$rds-font-weight-semibold: 600;
$rds-font-weight-bold: 700;
$rds-font-line-height-16: 1rem;
$rds-font-line-height-20: 1.25rem; // 8px Grid Note: Requires to be inside a container that is divisble by 8
$rds-font-line-height-24: 1.5rem;
$rds-font-line-height-28: 1.75rem; // 8px Grid Note: Requires to be inside a container that is divisble by 8
$rds-font-line-height-32: 2rem;
$rds-font-line-height-36: 2.25rem; // 8px Grid Note: Requires to be inside a container that is divisble by 8
$rds-font-line-height-40: 2.5rem;
$rds-font-line-height-44: 2.75rem; // 8px Grid Note: Requires to be inside a container that is divisble by 8
$rds-animation-easing: ease-out;
$rds-animation-speed: 86ms;
$rds-base-font-size: 16px; // Deprecated - do not use
$rds-base-unit-x: 8px; // Deprecated - do not use
$rds-base-unit-two-x: 16px; // Deprecated - do not use
$rds-base-unit-three-x: 24px; // Deprecated - do not use
$rds-base-unit-four-x: 32px; // Deprecated - do not use
$rds-base-unit-five-x: 40px; // Deprecated - do not use
$rds-base-unit-six-x: 48px; // Deprecated - do not use
$rds-base-unit-seven-x: 56px; // Deprecated - do not use
$rds-base-unit-eight-x: 64px; // Deprecated - do not use
$rds-base-body-font-size: 1.125rem;
$rds-base-body-line-height: 1.5rem;
$rds-base-body-min-width: 320px;
$rds-base-body-rendering: optimizeLegibility;
$rds-base-body-overflow-x: hidden;
$rds-base-body-overflow-y: scroll;
$rds-base-code-padding: 16px;
$rds-base-code-weight: normal;
$rds-base-code-size: 0.889rem;
$rds-base-code-family: monospace;
$rds-base-hr-background-color: #cccccc; // neutral-80
$rds-base-hr-height: 1px;
$rds-base-hr-margin: 24px 0;
$rds-base-pre-size: 0.889rem;
$rds-base-pre-padding: 16px 24px;
$rds-base-pre-code-font-size: 1rem;
$rds-base-small-size: 0.79rem;
$rds-base-strong-color: currentColor;
$rds-base-strong-weight: 700;
$rds-base-outline-color: #808080; // Deprecated - do not use | neutral-50
$rds-base-outline-style: solid;
$rds-border-radius-0: 0px;
$rds-border-radius-4: 4px;
$rds-border-radius-8: 8px;
$rds-border-radius-12: 12px;
$rds-border-radius-20: 20px;
$rds-border-radius-circle: 50%;
$rds-border-radius-rounded: 290486px;
$rds-breakpoints-mobile: 320px; // 1 column * max column width 288 + (16 margin * 2) = 320
$rds-breakpoints-tablet-portrait: 540px; // (1 * gap(16)) + (2 columns * max column width (248)) + (16 margin * 2) = 540
$rds-breakpoints-tablet-landscape: 768px; // (3 * gap(16)) + (4 columns * max column width (172)) + (16 margin * 2) = 768
$rds-breakpoints-desktop: 1048px; // (11 * gap(24)) + (12 columns * max column width (60)) + (16 margin * 2) = 1048
$rds-breakpoints-widescreen: 1336px; // (11 * gap(24)) + (12 columns * max column width (84)) = 1336
$rds-breakpoints-max-width: 1272px;
$rds-grid-columns-column-gap: 12px; // 12px - Space between columns - adjacent columns will have double this size (24px)
$rds-grid-columns-column-gap-touch: 8px; // 8px - Space between columns - adjacent columns will have double this size (16px)
$rds-outline-box-shadow-for-solid-color-width: 4px; // Deprecated - do not use | Use when box shadow is used to create the outline focus on dark solid elements
$rds-outline-width: 3px;
$rds-section-outer-gap: 32px; // The amount of spacing on both sides of viewport
$rds-section-outer-gap-touch: 16px; // The amount of spacing on both sides of viewport
$rds-spacing-size-8: 8px;
$rds-spacing-size-16: 16px;
$rds-spacing-size-24: 24px;
$rds-spacing-size-32: 32px;
$rds-spacing-size-40: 40px;
$rds-spacing-size-48: 48px;
$rds-spacing-size-64: 64px;
$rds-unit-size-1: 1px;
$rds-unit-size-3: 3px;
$rds-unit-size-4: 4px;
$rds-unit-size-6: 6px;
$rds-unit-size-7: 7px;
$rds-unit-size-10: 10px;
