@mixin c-apply-dropdown($variant-name: 'primary') {
  $variant: map-get($dropdown-variants, $variant-name);
  $variant-active: map-get($variant, 'active');
  $variant-hover: map-get($variant, 'hover');
  $variant-focus: map-get($variant, 'focus');

  display: inline-block;

  .rds-pseudolink-button {
    background-color: map-get($variant, background-color);
    background-image: get-dropdown-triangle-svg(16, encode-color(map-get($variant, color)));
    background-position: calc(100% - #{$rds-spacing-size-16}) 45%;
    background-repeat: no-repeat;
    border: 0;
    border-radius: $rds-border-radius-4;
    color: map-get($variant, color);
    padding: 12px $rds-spacing-size-40 12px $rds-spacing-size-16;

    &.is-active {
      background-image: get-dropdown-triangle-svg(16, encode-color(map-get($variant, color)), true);
    }

    &:disabled {
      background-image: get-dropdown-triangle-svg(16, encode-color($rds-color-neutral-80));
      color: $rds-color-neutral-80;
      cursor: not-allowed;
    }
  }

  .rds-dropdown-menu {
    border-radius: $rds-border-radius-4;
    box-shadow: map-get($variant, box-shadow);
    color: map-get($variant, color);
    // Elevation Chart: https://docs.google.com/spreadsheets/d/1lYqif4REy4S_vLvjZrGZU2XN3HF5LoEvUW8tWYDoA1k/edit#gid=0
    z-index: 120;
  }

  .rds-dropdown-menu-item {
    height: $rds-spacing-size-40;
  }

  .rds-link-footer {
    align-items: center;
    color: inherit;
    display: inline-flex;
    fill: currentColor;
    font-size: $size-14;
    font-weight: $control-font-weight;
    height: 100%;
    padding: 0 $rds-spacing-size-16;
    width: 100%;

    &:hover {
      background-color: map-get($variant-hover, background-color);
      cursor: pointer;
      text-decoration: none;
    }

    &:focus,
    &:active {
      outline: none;
    }

    &:focus {
      border-radius: $rds-border-radius-4;
      box-shadow: map-get($variant-focus, box-shadow);
    }

    &:active {
      background-color: map-get($variant-active, background-color);
      box-shadow: map-get($variant-active, box-shadow);
      color: map-get($variant-active, color);
    }
  }

  .has-rds-l-icon {
    margin-left: $rds-spacing-size-8;
  }

  .has-rds-r-icon {
    margin-right: $rds-spacing-size-8;
  }
}
