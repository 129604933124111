@import '../../../../sass/helpers/variables';
@import '../../../../sass/helpers/mixins';

.root {
    background: $bg_green;
    width: 100%;
}

.container {
    @include center-block;
    max-width: 900px;
    padding: 12px;
    text-align: center;
    display: flex;
    justify-content: center;

    &:focus {
        outline: none;
        border: 2px solid $blue;
    }
}

.checkMark {
    margin-right: 8px;
    @include sq(12px);
}

.text {
    color: $green;
    font-weight: 600;
}

@include mq-tablet-portrait {
    .checkMark {
        width: auto;
        height: auto;
    }
}
