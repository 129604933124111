@import './variables';

// Responsive media query breakpoints
@mixin mq-tablet-portrait {
    @media only screen and (min-width: $tablet-portrait-breakpoint) {
        @content;
    }
}

@mixin mq-tablet-landscape {
    @media only screen and (min-width: $tablet-landscape-breakpoint) {
        @content;
    }
}

@mixin mq-desktop {
    @media only screen and (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin mq-desktop-widescreen {
    @media only screen and (min-width: $desktop-widescreen-breakpoint) {
        @content;
    }
}

// Utilities
@mixin center-block {
    margin-left: auto;
    margin-right: auto;
}

@mixin center-flex-vh {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin mp0 {
    margin: 0;
    padding: 0;
}

@mixin reset-list {
    list-style: none;
    @include mp0;
}

@mixin reset-button {
    background: transparent;
    border: 0;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    white-space: nowrap;
    text-decoration: none;
    margin: 0;
    padding: 0;
}

@mixin remove-children-top-bottom-margin {
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

@mixin focus-highlight {
    &:focus:not(:focus-visible) {
        @content;
    }

    &:focus-visible {
        @content;
    }
}

@mixin link-focus-highlight($outline-width, $outline-color) {
    @include focus-highlight {
        outline: $outline-width solid $outline-color;
        outline-offset: 2px;
    }
}

@mixin rect($width, $height) {
    width: $width;
    height: $height;
}

@mixin sq($size) {
    @include rect($size, $size);
}
