%rds-button {
  @include apply-control-base($button-border-radius);
  @include unselectable;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;

  &.is-#{$prefix}-fullwidth {
    @include apply-control-fullwidth;
  }

  &.is-#{$prefix}-small {
    @include apply-control-small;
  }

  &.is-#{$prefix}-svg-btn {
    @include apply-button-svg-btn();
  }

  .has-rds-btn-icon-left {
    margin-right: map-get($spacing-sizes, 'size-8');
  }

  .has-rds-btn-icon-right {
    margin-left: map-get($spacing-sizes, 'size-8');
  }
}
