@import 'src/sass/uhc/tokens/uhc.scss';
@import 'src/sass/helpers/mixins';
@import 'src/sass/optum/variables';

.root {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 11;
    background: $bg_grey;
}

.container {
    display: flex;
    align-items: center;
    justify-content: right;
    height: 34px;
}

.nav {
    position: relative;
    display: flex;
    align-items: center;

    > * + * {
        margin-left: $rds-spacing-size-16;
    }

    .navItem {
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
    }
}
