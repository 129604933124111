@import 'src/sass/uhc/tokens/uhc.scss';
@import 'src/sass/helpers/mixins';
@import 'src/sass/helpers/variables';

$form-field-max-width: 408px;
$gutter-sm: 36px;
$gutter-lg: 56px;
$in-between-spacing-sm: $rds-spacing-size-24;
$in-between-spacing-lg: $rds-spacing-size-32;
$max-container-width: $tablet-portrait-breakpoint;

.root {
    max-width: $max-container-width;
    @include center-block;
    padding: $in-between-spacing-sm 16px;
    // Guard against text centering of parent ContentContainer
    text-align: left;

    @include mq-tablet-portrait {
        padding-left: $gutter-lg;
        padding-right: $gutter-lg;
    }

    // Apply consistent vertical spacing between children
    > * + * {
        margin-top: $in-between-spacing-sm;
    }

    @include mq-desktop {
        > * + * {
            margin-top: $in-between-spacing-lg;
        }
    }

    + .root {
        margin-top: $in-between-spacing-sm;

        @include mq-desktop {
            margin-top: $in-between-spacing-lg;
        }
    }
}

.hasBackground {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: $gutter-sm;
    padding-right: $gutter-sm;

    // Release the full bleed once the container reaches its max-width
    @include mq-tablet-portrait {
        @include center-block;
        padding-left: $gutter-lg;
        padding-right: $gutter-lg;
    }
}

.bgGrey {
    background-color: #f3f3f3;
}

.removeTopBottomSpacing {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
