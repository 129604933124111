@import '../../sass/optum/variables';
@import '../../sass/helpers/mixins';

$dot-default-color: #dfdfdf;

.root {
    color: #0096dc;
}

:global(.theme-optum) .root {
    color: $optum-blue;
}

.center {
    display: flex;
    justify-content: center;
}

.dots {
    display: flex;
    width: 36px;
    height: 10px;
    user-select: none;

    > * {
        border-radius: 50em;
        background-color: currentColor;
        width: 10px;
        height: 10px;
        // NOTE can't use `animation` property shorthand because CSS Module does weird things with the animation name
        // https://github.com/css-modules/css-modules/issues/141#issuecomment-293092109
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        + * {
            // TODO Remove `!important` once `ButtonGroup` CSS is removed that removes child spacing
            margin-left: 3px !important;
        }

        &:first-child {
            animation-name: dotFade1;
        }

        &:nth-child(2) {
            animation-name: dotFade2;
        }

        &:last-child {
            animation-name: dotFade3;
        }
    }
}

// NOTE Visual guide to transitions in the animation
// BvvvvG----G^^^^B
// G^^^^BvvvvG----G
// G----G^^^^BvvvvG

@keyframes dotFade1 {
    0% {
        background-color: currentColor;
    }

    33.33333% {
        background-color: $dot-default-color;
    }

    66.66667% {
        background-color: $dot-default-color;
    }

    100% {
        background-color: currentColor;
    }
}

@keyframes dotFade2 {
    0% {
        background-color: $dot-default-color;
    }

    33.33333% {
        background-color: currentColor;
    }

    66.66667% {
        background-color: $dot-default-color;
    }

    100% {
        background-color: $dot-default-color;
    }
}

@keyframes dotFade3 {
    0% {
        background-color: $dot-default-color;
    }

    33.33333% {
        background-color: $dot-default-color;
    }

    66.66667% {
        background-color: currentColor;
    }

    100% {
        background-color: $dot-default-color;
    }
}
