@import 'src/sass/uhc/tokens/uhc.scss';

.root {
    flex-direction: column;
    justify-content: flex-start;

    > * {
        margin-top: 0;
        margin-bottom: 0;

        + * {
            margin-top: $rds-spacing-size-24;
        }
    }

    &[hidden] {
        display: none;
    }
}
