@import '../../../../sass/helpers/variables';
@import '../../../../sass/helpers/mixins';

.root {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 11;
    border-bottom: 2px solid $border;
    background: $white;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 51px;

    @include mq-tablet-landscape {
        height: 65px;
    }
}

.logoImage {
    height: 30px;

    @include mq-tablet-landscape {
        height: 45px;
    }
}

.nav {
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;

    > * + * {
        margin-left: 16px;
    }

    // NOTE Temporary RDS override until header links are styled and rendered in line with RDS design
    a {
        font-weight: bold;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.helpButton {
    display: flex;
    font-weight: bold;
    font-size: 13px;
}

:global(.theme-optum) {
    .nav {
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
