@mixin apply-icon() {
  $icon-dimensions-24: map-get($icon-sizes, 'default');

  @extend %center-element;

  span {
    box-sizing: content-box !important;
    contain: strict;
    display: inline-block;
    fill: currentColor;
    height: $icon-dimensions-24;
    width: $icon-dimensions-24;
  }

  svg {
    display: block;
    fill: inherit;
    height: 100%;
    width: 100%;
  }

  @each $icon-size, $icon-value in $icon-sizes {
    @if $icon-size != 'default' {
      &.#{$prefix}-icon-#{$icon-size} {
        height: $icon-value;
        width: $icon-value;

        span {
          height: $icon-value;
          width: $icon-value;
        }
      }
    }
  }
}
