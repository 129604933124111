// NOTE: Using attribute selector id instead of #root because CSS Modules is changing the selector
html,
body,
[id='root'] {
    height: 100%;
}

body#hsid_body {
    opacity: 1; // to avoid adobe add opacity: 0
}

// Provide browser specific user select text
input {
    -webkit-user-select: text; // Chrome, Opera, Safari
    -moz-user-select: text; // Firefox 2+
    -ms-user-select: text; // IE 10+
    user-select: text; // Standard syntax
}

// Globally removing browser controllers for all input elements
input::-ms-reveal,
input::-ms-clear {
    display: none;
}

// IE 11 only css for active state
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    a {
        padding: 4px;
    }
}

// Remove the dotted line around the drop down selection in firefox
// TODO Remove once RDS Select component is implemented
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

// for IE 11 do not show default select arrow
// TODO Remove once RDS Select component is implemented
select::-ms-expand {
    display: none;
}

// Table responsive CSS class appended through AEM Content for mobile only
@media only screen and (max-width: 768px) {
    div[class='mobile-responsive-container'] {
        width: 100%;
        overflow-x: auto;
    }
}

a {
    // Add the link underline styling of the RDS Inline link variant to bare links that come from AEM
    &:not([class]) {
        text-decoration: underline;
    }

    // This removes the broken underline for the ® in linked "HealthSafe ID®" text
    > sup {
        display: inline-block;
    }

    // Prevent phone number links from breaking across multiple lines
    &[href^='tel'],
    &[href^='sms'] {
        white-space: nowrap;
    }

    // NOTE Remove once RDS ExternalLink component is implemented
    &[target='_blank']::after {
        // TODO implement a SASS function that will take a filepath and inline the file contents
        content: url("data:image/svg+xml,%3Csvg width='12px' height='12px' viewBox='0 0 12 12' aria-hidden='true' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23196ECF' stroke-width='1' fill='none'%3E%3Cpolyline points='5,0.5 0.5,0.5 0.5,11.5 11.5,11.5 11.5,7' stroke-linejoin='round' /%3E%3Cline x1='5.5' y1='6.5' x2='11' y2='1' stroke-linecap='round' stroke-width='1.5' /%3E%3Cpolyline points='7,0.5 11.5,0.5 11.5,5' stroke-linejoin='round' /%3E%3C/g%3E%3C/svg%3E");
        height: 15px;
        width: 15px;
    }
}

// Disable default focus ring for mouse users while keeping it for keyboard navigation
:focus:not(:focus-visible) {
    outline: none;
}
