/**
 * Block Elements
 */

p {
  margin-bottom: $rds-spacing-size-16;
  margin-top: 0;

  &:last-child,
  &:only-child {
    margin-bottom: 0;
  }
}
