// stylelint-disable-next-line value-keyword-case
$white: #fff;
$black: #000;
$grey: #f2f2f2;
$blue: #196ecf;
$green: #007000;

$text_black: #2d2d39;

$bg_grey: #fafafa;
$bg_green: #f4f9e9;

$border: #dfdfdf;

$focus-ring-color: #009de6;

$error-red: #aa2e23;

// These correspond to the artboard width and include the left/right gutters.
$mobile-breakpoint: 320px;
$tablet-portrait-breakpoint: 540px;
$tablet-landscape-breakpoint: 768px;
$desktop-breakpoint: 1048px;

$min-layout-width: 320px;
$max-layout-width: 1272px;

// Variables for circular illustrations used e.g. for security image and Reg flow step 5 check mark.
// The spacing is calculated and should be applied as follows:
//   ---
//    |  Additional margin
//   ---
//    |  Animation buffer
//   ---
//    |
//    |  Base circle background
//    |
//   ---
//    |  Animation buffer
//   ---
//    |  Additional margin
//   ---

// Width/height of the image circle background (not including the pulse animation which expands
// into the surrounding whitespace).
$circle-illustration-base-diameter-sm: 80px;
$circle-illustration-base-diameter-lg: 96px;

// Additional spacing for a single side around the base circle. The pulse animation expands into
// this space.
$circle-illustration-animation-buffer-radius-sm: 24px;
$circle-illustration-animation-buffer-radius-lg: 32px;

// The total width/height value for the base circle + top/bottom buffer spacing.
$circle-illustration-full-footprint-size-sm: $circle-illustration-base-diameter-sm +
    ($circle-illustration-animation-buffer-radius-sm * 2);
$circle-illustration-full-footprint-size-lg: $circle-illustration-base-diameter-lg +
    ($circle-illustration-animation-buffer-radius-lg * 2);

// Additional top/bottom margin to add around the total footprint
$circle-illustration-vertical-margin: 8px;
