@import '../../sass/optum/variables';
@import '../../sass/helpers/mixins';

.reset {
    @include reset-button;

    @include focus-highlight {
        box-shadow: 0 0 0 1px $white, 0 0 0 4px #0096dc;
        outline: none;
    }
}

.iconRoot {
    display: inline-flex;
}

.icon {
    color: #196ecf;
    align-self: center;

    @include focus-highlight {
        outline: 2px solid #009de6;
        outline-offset: 2px;
        border: none;
    }
}

:global(.theme-optum) {
    .icon {
        color: $optum-blue;
    }

    .reset {
        @include focus-highlight {
            box-shadow: none;
            outline: 2px solid $optum-blue-dark;
            outline-offset: 2px;
        }
    }

    @include focus-highlight {
        outline-color: #009de6;
    }
}
