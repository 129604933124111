@mixin tablet-portrait {
  @media screen and (min-width: $tablet-portrait) {
    @content;
  }
}

@mixin tablet-portrait-only {
  @media screen and (min-width: $tablet) and (max-width: $tablet-portrait - 1px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $tablet-portrait - 1px) {
    @content;
  }
}

@mixin u-set-breakpoint($breakpoint: null) {
  @if ($breakpoint and variable-exists('breakpoints') and map-has-key($breakpoints, $breakpoint)) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @content;
  }
}
