@mixin o-html-element {
  background-color: $base-background-color;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: $base-min-width;
  overflow-x: $base-overflow-x;
  overflow-y: $base-overflow-y;
  text-rendering: $base-rendering;
  text-size-adjust: 100%;
}
