.section {
  @include touch {
    padding: $section-padding-touch;
  }
}

.section:first-child,
.section.is-first {
  padding-top: $section-first-child-padding-top;
}

.section:last-child,
.section.is-last {
  padding-bottom: $section-last-child-padding-bottom;
}
