@import '../../../sass/helpers/variables';
@import '../../../sass/helpers/mixins';

$gutter-sm: 16px;
$gutter-lg: 24px;

.root {
    min-width: $min-layout-width;
    width: 100%;
    max-width: $max-layout-width + $gutter-lg + $gutter-lg;
    padding: 0 $gutter-sm;
    // The less supported bottom padding comes second in case a browser doesn't recognize it and will
    // use the first setting
    // https://css-tricks.com/the-notch-and-css/ for env(safe-area-inset-bottom)
    // Also, using title case for `Max` because of collision between SASS and CSS functions
    // https://css-tricks.com/when-sass-and-new-css-features-collide/#the-solution
    padding-left: Max($gutter-sm, calc(10px + env(safe-area-inset-left)));
    padding-right: Max($gutter-sm, calc(10px + env(safe-area-inset-right)));
    @include center-block;

    @include mq-desktop {
        padding-left: $gutter-lg;
        padding-right: $gutter-lg;
    }
}

.removeEndingMargins {
    @include remove-children-top-bottom-margin;
}
