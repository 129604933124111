@import 'src/sass/uhc/tokens/uhc.scss';
@import 'src/sass/helpers/variables';

.fieldWrapper {
    background-color: #fff;
    outline: 1px solid $rds-color-neutral-50;
    outline-offset: unset;
    position: relative;

    &.isFocused {
        outline: 2px solid $focus-ring-color;
    }

    &.isError {
        outline: 2px solid #f04f2a;
    }
}

.label {
    white-space: nowrap;
}

.field {
    display: block;
    width: 100%;
    border: 0;
    font-size: $rds-font-size-18;

    &:focus {
        outline: 0;
    }
}

.fieldControls {
    align-items: center;

    > * {
        flex: 0 0 auto;

        + * {
            border-left: 1px solid $rds-color-neutral-50;
            padding-left: $rds-spacing-size-8;
            margin-left: $rds-spacing-size-8;
        }
    }
}

.fieldControlWrapper {
    align-items: center;
}

.validMessage {
    position: absolute;
    right: -#{$rds-spacing-size-32};
    top: 50%;
    transform: translateY(-50%);
}

// displaying remembered username
.maskedUsername::placeholder {
    color: #2e2d3a;
    opacity: 1;
}
