@mixin c-apply-radio() {
  @include apply-typography('body-3');
  $radio-base-color: map-get($radio-properties, 'base-color');
  $radio-base-input-color: map-get($radio-properties, 'base-color-input');
  $radio-hover-color: map-get($radio-properties, 'hover-color');
  $radio-active-color: map-get($radio-properties, 'active-color');
  $radio-weight: map-get($radio-properties, 'weight');
  $radio-text-mt: rem(map-get($radio-properties, 'h-margin-top'));
  $radio-icon-size: rem(24px);
  $radio-inner-icon-size: rem(16px);
  $radio-inner-icon-position: rem(8px);
  $radio-border-width: rem(2px);

  align-items: center;
  color: $radio-base-color;
  cursor: pointer;
  display: inline-flex;
  font-weight: $radio-weight;
  justify-content: center;

  &:hover {
    .#{$prefix}-radio-icon {
      border-color: $radio-hover-color;
    }
  }

  // CSS only approach to contructing the Radio icon
  .#{$prefix}-radio-icon {
    border: $radio-border-width solid $radio-base-input-color;
    border-radius: map-get($border-radiuses, 'circle');
    box-sizing: border-box;
    color: $radio-active-color;
    height: $radio-icon-size;
    margin-right: rem(map-get($spacing-sizes, 'size-8'));
    position: relative;
    width: $radio-icon-size;

    + span {
      margin-top: $radio-text-mt;
    }
  }

  input[type='radio'] {
    @include u-sr-only();
    opacity: 0; // Added to ensure checkbox is not shown in IE11

    &:checked {
      + .#{$prefix}-radio-icon {
        border-color: currentColor;

        &::after {
          background: currentColor;
          border-radius: map-get($border-radiuses, 'circle');
          content: '';
          height: $radio-inner-icon-size;
          left: 50%;
          margin: -#{$radio-inner-icon-position} 0 0 -#{$radio-inner-icon-position};
          position: absolute;
          top: 50%;
          width: $radio-inner-icon-size;
        }
      }
    }

    &:focus + .#{$prefix}-radio-icon {
      box-shadow: $focus-box-shadow-with-inset;
    }
  }

  &.is-rds-disabled input[type='radio']:checked ~ .#{$prefix}-radio-icon {
    border-color: $radio-base-color;

    &::after {
      background: $radio-base-color;
    }
  }
}
