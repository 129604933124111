@mixin apply-button-variant($variant-name: 'primary') {
  $variant: map-get($button-variants, $variant-name);
  $variant-active: map-get($variant, 'active');
  $variant-hover: map-get($variant, 'hover');
  $variant-focus: map-get($variant, 'focus');

  @extend %rds-button;
  background-color: map-get($variant, 'background-color');
  border-color: map-get($variant, 'border-color');
  // To remove blinking cursor in FF
  caret-color: transparent;
  color: map-get($variant, 'color');

  @if $variant-name == 'pseudolink' {
    height: initial;
    padding: 0;
  }

  &:focus {
    background-color: map-get($variant-focus, 'background-color');
    border-color: map-get($variant-focus, 'border-color');
    box-shadow: map-get($variant-focus, 'box-shadow');
    color: map-get($variant-focus, 'color');
  }
  &:hover {
    background-color: map-get($variant-hover, 'background-color');
    border-color: map-get($variant-hover, 'border-color');
    color: map-get($variant-hover, 'color');
    text-decoration: map-get($variant-hover, 'text-decoration');
  }
  &:active {
    background-color: map-get($variant-active, 'background-color');
    border-color: map-get($variant-active, 'border-color');
    box-shadow: map-get($variant-active, 'box-shadow');
    color: map-get($variant-active, 'color');
  }
}

@mixin apply-button-svg-btn() {
  $nav-button-size: rem(40px);

  border-radius: map-get($border-radiuses, 'circle');
  height: $nav-button-size;
  padding: 0;
  width: $nav-button-size;

  .h-#{$prefix}-nav-button-back-caret {
    margin-left: rem(-2px);
  }

  .h-#{$prefix}-nav-button-forward-caret {
    margin-left: rem(1px);
  }
}
