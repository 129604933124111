@mixin apply-control-base($border-radius-override: null) {
  @include control;
  @if $border-radius-override {
    border-radius: $border-radius-override;
  }
  font-family: $control-font-family;
  font-size: $control-font-size;
  font-weight: $control-font-weight;
}

@mixin apply-control-fullwidth {
  display: flex;
  width: 100%;
}

@mixin apply-control-small {
  font-size: $control-font-size-small;
  height: $control-height-small;
}
