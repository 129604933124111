@import '../helpers/mixins';
@import './variables';

a[href]:not([class*='button']),
a[class^='rds-link'] {
    color: $optum-blue;
    transition: color;

    &:hover {
        text-decoration: none;
    }

    @include link-focus-highlight(2px, $optum-blue-dark);
}
