@import 'src/sass/helpers/variables';
@import 'src/sass/helpers/mixins';

.root {
    // Enforce full height layout
    height: 100%;
    display: flex;
    flex-direction: column;

    > * {
        flex: 1 0 auto;
    }

    > :first-child {
        flex: 0 0 auto;
    }

    > :last-child {
        flex: 0 0 auto;
    }
}

.skipLink {
    position: absolute;
    background-color: $white;
    outline-offset: -2px !important;
    transform: translateY(-200%);
    transition: transform 0.3s;
    z-index: 50;
    left: 8px;
    top: 8px;

    @include focus-highlight {
        transform: translateY(0%);
    }
}
