$base-font-size: $rds-base-font-size;
$base-background-color: $rds-color-white;
$base-min-width: $rds-base-body-min-width;
$base-rendering: $rds-base-body-rendering;
$base-overflow-x: $rds-base-body-overflow-x;
$base-overflow-y: $rds-base-body-overflow-y;

$body-family: $rds-font-family;
$body-color: $rds-color-neutral-30;
$body-font-size: $rds-base-body-font-size;
$body-weight: $weight-normal;
$body-line-height: $rds-base-body-line-height;

$code-family: $rds-base-code-family;
$code-padding: $rds-base-code-padding;
$code-weight: $rds-base-code-weight;
$code-size: $rds-base-code-size;

$small-font-size: $rds-base-small-size;

$hr-background-color: $rds-base-hr-background-color;
$hr-height: $rds-base-hr-height;
$hr-margin: $rds-base-hr-margin;

$strong-color: $rds-base-strong-color;
$strong-weight: $rds-base-strong-weight;

$pre-font-size: $rds-base-pre-size;
$pre-padding: $rds-base-pre-padding;
$pre-code-font-size: $rds-base-pre-code-font-size;

$render-mode: $rds-base-body-rendering;

/* Generic Styling
   ========================================================================== */

html {
  @include o-html-element;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

body,
button,
input,
select,
textarea {
  font-family: $body-family;
}

code,
pre {
  font-family: $code-family;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
}

body {
  color: $body-color;
  font-size: $body-font-size;
  font-weight: $body-weight;
  line-height: $body-line-height;
}

// Inline
strong {
  color: $strong-color;
  font-weight: $strong-weight;
}

a {
  cursor: pointer;
  strong {
    color: currentColor;
  }
}

code {
  background-color: $code-background;
  color: $code;
  font-size: $code-size;
  font-weight: $code-weight;
  padding: $code-padding;
}

hr {
  background-color: $hr-background-color;
  border: 0;
  display: block;
  height: $hr-height;
  margin: $hr-margin;
}

img {
  height: auto;
  max-width: 100%;
}

input[type='checkbox'],
input[type='radio'] {
  vertical-align: baseline;
}

small {
  font-size: $small-font-size;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

// Block

fieldset {
  border: 0;
}

pre {
  @include overflow-touch;
  background-color: $pre-background;
  color: $pre;
  font-size: $pre-font-size;
  overflow-x: auto;
  padding: $pre-padding;
  white-space: pre;
  word-wrap: normal;

  code {
    background-color: transparent;
    color: currentColor;
    font-size: $pre-code-font-size;
    padding: 0;
  }
}

table {
  td,
  th {
    color: $text-strong;
    vertical-align: top;
    &:not([align]) {
      text-align: inherit;
    }
  }
}
